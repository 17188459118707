import '/assets/styles/pages/search.scss';

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import searchFilters from '/data/listing-filters/types/search'

// Services
import productServices from '/services/product';

// Functions
import isExact from 'pstv-commerce-tools/functions/is-exact';

// Sections
import ListingHead from '/views/sections/listing-head';
import ListingFilters from '../sections/listing-filters';
import Listing from '/views/sections/listing';

// Context
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'
import { useHead } from 'pstv-commerce-tools/utilities/head';
import { ListingProvider, decodeQueryString, useListing } from 'pstv-commerce-tools/utilities/listing';
import Breadcrumbs from '/views/sections/breadcrumbs';

const getSearchProducts = (searchTerm, query) => {
	return productServices.getSearchProducts(searchTerm, query)
}
const getSearchFilters = (searchTerm, query) => {
	return productServices.getSearchFilters(searchTerm, query)
}

const parseHead = (searchTerm) => {
	return {
		title: `"${searchTerm}": Arama Sonuçları`,
		description: `"${searchTerm}" araması sonuçları.`,
	}
}

const defaultQuery = {
	page: '1',
	// order: 'ovd',
}

const SearchQueryProvider = ({match, ...props}) => {
	return (
		<ListingProvider
			externalParams={{ searchTerm: match.params.search }}
			availableFilters={searchFilters}
			initialFilterData={props.pageProps?.filters}
			queryString={match.params.query}
			defaultQuery={defaultQuery}>
			<Search {...props} />
		</ListingProvider>
	)
}

const Search = ({ pageProps }) => {
	const { inputQuery, outputData, setFilterData } = useListing();
	const { redirect } = useNavigator();

	const { setHead } = useHead();
	const [{products, pagination, fetchedQuery}, setProducts] = useState(pageProps?.productData ?? {products: null, fetchedQuery: null, pagination: null});

	const { searchTerm } = inputQuery;

	useEffect(() => {
		setTimeout(() => {
			setHead(parseHead(searchTerm));
		}, 100)
	}, [searchTerm])

	useEffect(() => {
		if(inputQuery.searchTerm && !isExact(fetchedQuery, inputQuery)) {
			const { searchTerm, ...queryParams } = inputQuery;
			setProducts({ products: null, pagination: null, fetchedQuery: inputQuery });
			getSearchProducts(searchTerm, queryParams).then(productData => {
				setProducts({ ...productData, fetchedQuery: inputQuery})
			});
			getSearchFilters(searchTerm, queryParams).then(filterData => {
				setFilterData(filterData);
			});
		}
	}, [inputQuery, fetchedQuery])

	useEffect(() => {
		if(searchTerm === outputData?.externalParams?.searchTerm) {
			const params = {
				search: searchTerm,
			};
	
			if(outputData.queryString && outputData.queryString.length) {
				params.query = outputData.queryString;
			}
			redirect('search', params);
		}
	}, [outputData, searchTerm])

	return (
		<>
			<Breadcrumbs data={[{ name: 'Arama' }]} />
			<ListingHead title={`Arama sonuçları: "${searchTerm}"`} />
			<ListingFilters />
			<Listing
				emptyMessage={<>
					<strong>&quot;{searchTerm}&quot;</strong><br /> araması için bir sonuç bulunamadı.
				</>}
				products={products}
				pagination={pagination} />
		</>
	)
}

Search.propTypes = {
	pageProps: PropTypes.object
}

SearchQueryProvider.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		const rawQuery = decodeQueryString({
			availableFilters: searchFilters,
			queryString: match.params.query,
			defaultQuery,
			externalParams: { searchTerm: match.params.search }
		});

		const { searchTerm, ...query } = rawQuery;
		Promise.all([
			getSearchProducts(searchTerm, query),
			getSearchFilters(searchTerm, query),
		]).then(([productData, filters]) => {
			resolve({
				pageProps: {
					filters,
					productData: {
						...productData,
						fetchedQuery: rawQuery,
					},
				},
				head: parseHead(searchTerm),
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

SearchQueryProvider.propTypes = {
	match: PropTypes.object,
	pageProps: PropTypes.object,
}

export default SearchQueryProvider
